export const SAVE_SUCCESS = "Salvado correctamente!"
export const UPDATE_SUCCESS = "Actualizado correctamente!"
export const ERROR_SERVER = "Error del servidor!. Contactar con el admin"
export const FIELD_REQUIRED = "Campo requerido"
export const SELECT_OPTION = 'Seleccionar opción'
export const DELETE_SUCCESS = 'Eliminado correctamente!'
export const PRODUCT_IN_CAR = "Este producto ya se encuentra en el carrito"
export const BAD_REQUEST = "Solicitud incorrecta!. Contactar con el administrador"
export const CHECK_CREDENTIALS = "Por favor revisar credenciales"
export const CAMPO_MAYOR_CERO = "Campo mayor a cero"
export const FIELD_NUMERIC = "Campo numérico"
export const FIELD_INTEGER = "Campo numérico entero"
export const CAMPO_MENOR_CIEN = "Campo menor o igual a 100"
export const FIELD_EMAIL = "Correo electrónico no válido"
export const SUCCESS_REQUEST = "Solicitud exitosa"