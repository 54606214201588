const states = [
  { id: "AG", name: "Aguascalientes" },
  { id: "BC", name: "Baja California" },
  { id: "BCS", name: "Baja California Sur" },
  { id: "CM", name: "Campeche" },
  { id: "CS", name: "Chiapas" },
  { id: "CH", name: "Chihuahua" },
  { id: "CX", name: "Ciudad de México" },
  { id: "CO", name: "Coahuila" },
  { id: "CL", name: "Colima" },
  { id: "DG", name: "Durango" },
  { id: "GT", name: "Guanajuato" },
  { id: "GR", name: "Guerrero" },
  { id: "HG", name: "Hidalgo" },
  { id: "JC", name: "Jalisco" },
  { id: "EM", name: "México" },
  { id: "MI", name: "Michoacán" },
  { id: "MO", name: "Morelos" },
  { id: "NA", name: "Nayarit" },
  { id: "NL", name: "Nuevo León" },
  { id: "OA", name: "Oaxaca" },
  { id: "PU", name: "Puebla" },
  { id: "QT", name: "Querétaro" },
  { id: "QR", name: "Quintana Roo" },
  { id: "SL", name: "San Luis Potosí" },
  { id: "SI", name: "Sinaloa" },
  { id: "SO", name: "Sonora" },
  { id: "TB", name: "Tabasco" },
  { id: "TM", name: "Tamaulipas" },
  { id: "TL", name: "Tlaxcala" },
  { id: "VE", name: "Veracruz" },
  { id: "YU", name: "Yucatán" },
  { id: "ZA", name: "Zacatecas" },
];

export { states };
